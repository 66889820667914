.shared-layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sticky-top {
  position: sticky;
  top: 0;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}
