/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

html {
  font-size: 100%;
} /*16px*/

:root {
  /* colors */
  --dark: #011627;
  --fade-dark: #1e2d3d;
  --button-dark: #1c2b3a;
  --grey: #607b96;
  --white: #fff;
  --light-white: #e5e9f0e7;
  --light-blue: #4d5bce;
  --green: #43d9ad;
  --brown: #e99287;
  --yellow: #fea55f;
  --black: #01080e;

  /* font */
  --bodyFont: 'Fira Code';
  --textColor: var(var(--white));
}

body {
  margin: 0;
  background: var(--dark);
  font-family: var(--bodyFont);
  color: var(--light-white);
}

body::-webkit-scrollbar {
  display: none;
}

p {
  /* color: #607b96; */
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  color: var(--textColor);
  font-family: var(--bodyFont);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: normal;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}
